class TabbedStrip {

	constructor() {
		this.blocks = document.querySelectorAll('.block-tabbed-strip');

		document.addEventListener('DOMContentLoaded', () => {
			this.init();
		});
	}

	/**
	 * Example function to run class logic
	 * Can access `this.block`
	 */
	init() {

		this.blocks.forEach((block) => {

			const carousel = block.querySelector('.swiper');

			const swiper = new Swiper(carousel, {
				slidesPerView: 1,
				effect: 'coverflow',
				cubeEffect: {
					slideShadows: false,
					shadow: false,
				},
				coverflowEffect: {
					slideShadows: false,
					stretch: -100,
				},
				fadeEffect: {
					crossFade: true
				},
				speed: 1000,
			});

			// on slidechange
			swiper.on('slideChange', () => {
				const tabLinks = block.querySelectorAll('.block-tabbed-strip__tab');

				tabLinks.forEach((link) => link.classList.remove('active') );
				tabLinks[swiper.realIndex].classList.add('active');
			});

			const tabLinks = block.querySelectorAll('.block-tabbed-strip__tab');

			if (tabLinks.length > 0) {
				tabLinks.forEach((link) => {
					link.addEventListener('click', (e) => {

						// Remove active class from all tabs
						tabLinks.forEach((link) => {
							link.classList.remove('active');
						});

						// Add active class to clicked tab
						e.target.classList.add('active');

						const tab = e.target.getAttribute('data-tab-id');
						swiper.slideTo(tab);
					});
				});
			}

		});

	}
}

new TabbedStrip();
